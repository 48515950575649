import { IconLayer } from "@deck.gl/layers";
import StartIcon from "../css/start.svg";

const useRouteStartLayer = (startCoords) => {
  const startLayer = new IconLayer({
    id: "start-icon-layer",
    data: startCoords,
    pickable: false,
    wrapLongitude: true,
    getPosition: startCoords,
    sizeScale: 0,
    sizeMinPixels: 40,
    sizeUnits: "meters",
    getIcon: () => ({
      url: new URL(StartIcon, import.meta.url).href,
      width: 30,
      height: 35,
      anchorY: 32,
    }),
  });

  return startLayer;
};

export default useRouteStartLayer;
