/* istanbul ignore file */
import LoadScreen from "@/components/LoadScreen";
import config from "@/config";
import {
  dataLayerActual,
  dataLayerExpectedInside,
  dataLayerExpectedOuter,
} from "@/features/map/mapStyle";
import useGetArrivalRoutesQuery from "@/framework/query/routes/useGetArrivalRoutesQuery";
import { useGeoCreds } from "@/framework/hooks/userHooks";
import { Signer } from "@aws-amplify/core";
import { MapboxOverlay } from "@deck.gl/mapbox/typed";
import _ from "lodash";
import maplibregl from "maplibre-gl";
import { useMemo, useState } from "react";
import Map, { Layer, Source, useControl } from "react-map-gl";
import useRouteEndLayer from "./routes/useRouteEndLayer";
import useRouteStartLayer from "./routes/useRouteStartLayer";

const ReportMap = ({ missionId }) => {
  const geoCreds = useGeoCreds();
  const { data: arrivalData, isFetched } = useGetArrivalRoutesQuery(missionId);

  const actualRoute = isFetched
    ? arrivalData?.actual_route.map((coords) => [coords.lon, coords.lat])
    : [];
  const expectedRoute = isFetched
    ? arrivalData?.expected_route.map((coords) => [coords.lon, coords.lat])
    : [];
  const startLayer =
    isFetched && !_.isEmpty(actualRoute)
      ? useRouteStartLayer(actualRoute[0])
      : null;
  const endLayer =
    isFetched && !_.isEmpty(actualRoute)
      ? useRouteEndLayer(actualRoute[actualRoute.length - 1])
      : null;

  const actualRoutePath = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: actualRoute,
    },
  };

  const expectedRoutePath = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: expectedRoute,
    },
  };

  const [mapLoaded, setMapLoaded] = useState(false);

  const transformRequest = (url, resourceType) => {
    if (
      resourceType === "Style" &&
      url.indexOf("https://maps.geo.us-east-1.amazonaws.com") > -1
    ) {
      url = `${config.AWS_MAP_URL}`;
    }
    if (url.includes("amazonaws.com")) {
      return {
        url: Signer.signUrl(url, {
          access_key: geoCreds?.accessKeyId,
          secret_key: geoCreds?.secretAccessKey,
          session_token: geoCreds?.sessionToken,
        }),
      };
    }

    return { url: url || "" };
  };

  const initialView = useMemo(() => {
    const route = actualRoute?.length > 0 ? actualRoute : expectedRoute;
    if (isFetched) {
      // Calculate the bounding box
      const minLat = Math.min(...route.map((d) => d[1]));
      const maxLat = Math.max(...route.map((d) => d[1]));
      const minLon = Math.min(...route.map((d) => d[0]));
      const maxLon = Math.max(...route.map((d) => d[0]));

      // Calculate the center of the bounding box
      const centerLat = (minLat + maxLat) / 2;
      const centerLon = (minLon + maxLon) / 2;

      // Define the initial view state
      return {
        latitude: centerLat,
        longitude: centerLon,
        zoom: 10,
        bearing: 0,
        pitch: 0,
      };
    } else
      return {
        longitude: -98.5795,
        latitude: 39.8283,
        zoom: 4,
      };
  }, [actualRoute, expectedRoute, isFetched]);

  return geoCreds && isFetched ? (
    <>
      <Map
        id="mappanel"
        style={{
          transition: "opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
          opacity: mapLoaded ? 1 : 0,
          filter: mapLoaded ? "blur(0px)" : "blur(100px)",
          pointerEvents: mapLoaded ? "auto" : "none",
        }}
        initialViewState={initialView}
        dragRotate={true}
        touchRotate={false}
        mapStyle={`https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/nav-map-esri/style-descriptor`}
        mapLib={maplibregl}
        transformRequest={transformRequest}
        dragPan={true}
        scrollZoom={true}
        doubleClickZoom={false}
        onLoad={() => {
          setMapLoaded(true);
        }}
        attributionControl={false}
      >
        <Source id="expectedRoute" type="geojson" data={expectedRoutePath}>
          <Layer {...dataLayerExpectedOuter} />
          <Layer {...dataLayerExpectedInside} />
        </Source>
        <Source id="actualRoute" type="geojson" data={actualRoutePath}>
          <Layer {...dataLayerActual} />
        </Source>
        <DeckGLOverlay layers={[startLayer, endLayer]} />
      </Map>
    </>
  ) : (
    <LoadScreen />
  );
};

const DeckGLOverlay = (props) => {
  const overlay = useControl(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

export default ReportMap;
