import _ from "lodash";
import { DateTime } from "luxon";

/**
 * Gets UTC date start Unix Timestamp
 *
 * @param {Date} date
 */
export function getUTCStartDateTimeStamp(date = new Date()) {
  //convert date so criteria reflects how it is saved in Dynamo DB
  //set day start (12 AM in browser time zone)
  date.setHours(0, 0, 0, 0);
  //shift for the UTC offset, so now it is day start in UTC (needed for Dynamo DB date index)
  date = new Date(date - date.getTimezoneOffset() * 60000);
  //get adjusted unix time stamp (drop milliseconds)
  var dateTimeStamp = (date.getTime() / 1000) | 0;

  return dateTimeStamp;
}

/**
 * @param {string} date The date to format, in ISO 8601 format, epoch time also supported.
 * @param {string} format - Optional parameter - The specified luxon format.
 * @param {string} invalidValueDefault - Optional Localized value to show when date is invalid - default is null.
 * @returns The formatted date time.
 */
export function getFormattedDate(
  date,
  format = "LL/dd hh:mm a ZZZZ",
  invalidValueDefault = "---"
) {
  if (date == null || date == undefined) {
    return invalidValueDefault;
  }
  let d;

  if (typeof date === "string") {
    d = DateTime.fromISO(date);
  } else {
    d = DateTime.fromMillis(date);
  }

  if (date && d.isValid) {
    return d.toFormat(format);
  } else {
    return invalidValueDefault;
  }
}

/**
 * @param {string} date The date to format, in ISO 8601 format, epoch time also supported.
 * @returns The formatted time.
 */
export function getTime(date) {
  return getFormattedDate(date, "hh:mm ZZZZ");
}

/**
 * @param {string} date The date to format, in ISO 8601 format, epoch time also supported.
 * @returns The formatted time.
 */
export function getTime24(date) {
  return getFormattedDate(date, "HH:mm ZZZZ");
}

/**
 * @param {string} state The state of a mission
 * @returns Pre-Trip or Post-Trip
 */
export function getActionStage(state) {
  switch (state) {
    case "IN_PROGRESS":
    case "ARRIVED":
    case "COMPLETED":
      return "POST-TRIP";
    default:
      return "PRE-TRIP";
  }
}

/**
 * @param {string} text Text to truncate
 * @returns If string is too large, truncate it
 */
export function truncateText(text) {
  return text.length <= 15 ? text : `${text.slice(0, 7)}...${text.slice(-7)}`;
}

/**
 * @param {string} state Map action mission_state to mission.state
 * @returns mapped state
 */
export function mapToActionState(state) {
  switch (state) {
    case "SCHEDULED":
    case "PREPARING":
      return "SCHEDULED";
    default:
      return state;
  }
}

export const states = {
  SCHEDULED: "SCHEDULED",
  PREPARING: "PREPARING",
  REVIEWING: "REVIEWING",
  CLEARED: "CLEARED",
  WAIT_ACCEPT: "WAIT_ACCEPT",
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  ACCEPTED: "ACCEPTED",
  ARRIVED: "ARRIVED",
  CANCELED: "CANCELED",
  DEPARTURE_BLOCKED: "DEPARTURE_BLOCKED",
  DST_CHG_REQ: "DST_CHG_REQ",
};

const stateDisplayMap = {
  [states.SCHEDULED]: "Pre-Trip",
  [states.PREPARING]: "Pre-Trip",
  [states.REVIEWING]: "Pre-Trip",
  [states.CLEARED]: "Pre-Trip",
  [states.WAIT_ACCEPT]: "Pre-Trip",
  [states.PENDING]: "Pre-Trip",
  [states.IN_PROGRESS]: "In Transit",
  [states.ARRIVED]: "Post-Trip",
  [states.COMPLETED]: "Post-Trip",
  [states.ACCEPTED]: "Accepted",
  [states.CANCELED]: "Canceled",
  [states.DEPARTURE_BLOCKED]: "Departure Blocked",
  [states.DST_CHG_REQ]: "Destination Change Request",
};

export function getStateDisplay(state) {
  return stateDisplayMap[state] || state;
}

export function capitalizeFirstLetter(input) {
  if (!input) {
    return "--";
  }
  return input
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

export function toTitleCase(text) {
  if (!text) return "N/A";

  return text
    .toLowerCase()
    .split(", ")
    .map((part, index) =>
      index === 0
        ? part
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        : part.toUpperCase()
    )
    .join(", ");
}

export function getDatetimeDisplayText(mission) {
  let prefix = "";
  let displayDT = "";
  if (mission.state === states.IN_PROGRESS) {
    prefix = "arrives at ";
    displayDT = mission.tms_schedule_arrival_time;
  } else if (
    mission.state === states.ARRIVED ||
    mission.state === states.COMPLETED
  ) {
    prefix = "arrived at ";
    displayDT = mission.tms_schedule_arrival_time;
  } else {
    prefix = "departs at ";
    displayDT = mission.tms_schedule_departure_time;
  }
  return prefix + getTime24(displayDT);
}
