import { Box, Button, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ActionsApi } from "@/framework/api/ActionsApi";
import { toast } from "react-toastify";

const UnAssignModal = ({ handleClose, actionId, mission_uuid }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () =>
      ActionsApi.deleteActionAssignV2({
        actionUuid: actionId,
      }),
    onSuccess: (data) => {
      toast.success("Successfully un-assigned action", data);
      queryClient.invalidateQueries({
        queryKey: ["actions", mission_uuid],
      });
      queryClient.invalidateQueries({
        queryKey: ["missions"],
      });
    },
  });

  const confirmUnassign = async (event) => {
    event.stopPropagation();
    try {
      await mutation.mutateAsync();
    } catch (err) {
      toast.error("Unable to un-assigned action");
    }
    handleClose(event);
  };

  return (
    <Box className="confirmation__box">
      <Box className="confirmation__box__text">
        <Typography>
          Are you sure you want to remove yourself as the assigned Hub Operator
          for this task?
        </Typography>
      </Box>
      <Box className="confirmation__box__button-group">
        <Button
          data-testid="close-module"
          onClick={handleClose}
          className="cancel"
        >
          No
        </Button>
        <Button
          data-testid="unassign-user"
          onClick={confirmUnassign}
          className="confirm"
        >
          Yes, remove me
        </Button>
      </Box>
    </Box>
  );
};

export default UnAssignModal;
