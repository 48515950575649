import { Alert, Box } from "@mui/material";
import { Link } from "react-router-dom";

const MissionVehicleAssignedBanner = ({ vehicleId, loadId, isV2 }) => (
  <Box>
    <Alert severity="info">
      The vehicle: {vehicleId} is currently being used on mission:&nbsp;
      {isV2 ? (
        <Link
          data-testid={`mission-link-${loadId}`}
          className="task-link"
          to={`/missions/v2/${encodeURIComponent(loadId)}/details`}
          target="_blank"
        >
          <span>{loadId}</span>
        </Link>
      ) : (
        <Link
          data-testid={`mission-link-${loadId}`}
          className="task-link"
          to={`/missions/${encodeURIComponent(loadId)}/details`}
          target="_blank"
        >
          <span>{loadId}</span>
        </Link>
      )}
    </Alert>
  </Box>
);

export default MissionVehicleAssignedBanner;
