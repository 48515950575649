import getToken from "@/framework/api/getToken";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { isEmpty } from "lodash";

const ROUTE_ADVISOR_API_URL = String(
  import.meta.env.VITE_ROUTE_ADVISOR_API_URL
);

const useGetRouteQuery = (routeId: string) => {
  /* istanbul ignore next */
  const getRouteQuery = useQuery({
    queryKey: ["route-advisor-route", routeId],
    queryFn: async () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      (
        await axios.get(`${ROUTE_ADVISOR_API_URL}/v1/routes/${routeId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken()}`,
          },
        })
      ).data,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    enabled: !isEmpty(routeId),
  });

  return getRouteQuery;
};

export default useGetRouteQuery;
