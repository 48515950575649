import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useNavigate, Outlet } from "react-router-dom";
import { useTabHandler } from "../../../framework/hooks/missionTabsHooks";
import Task from "../../tasks/Task";

import "./MissionTabs.scss";

const MissionTabs = ({ actions, mission }) => {
  let navigate = useNavigate();
  let currentTab = useTabHandler();

  const handleTabChange = (_event, newValue) => {
    navigate(newValue, { replace: true });
  };

  return (
    <Box className="mission-tabs" data-testid="mission-tabs">
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            className="mission-tabs__tablist"
            onChange={handleTabChange}
            aria-label="mission-tabs"
          >
            <Tab label="Tasks" value="tasks" data-testid="task-tab" />
            <Tab label="Health" value="health" data-testid="health-tab" />
          </TabList>
        </Box>
        <Outlet />
        <>
          <TabPanel className="mission-tabs__taskpanel" value="tasks">
            <Box className="mission-tabs__taskpanel__tasks">
              {actions
                .sort((a) => a.state)
                .reverse()
                .map((action) => {
                  return (
                    <Task key={action.uuid} mission={mission} action={action} />
                  );
                })}
            </Box>
          </TabPanel>
          <TabPanel value="health">Health Placeholder</TabPanel>
        </>
      </TabContext>
    </Box>
  );
};

export default MissionTabs;
