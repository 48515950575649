import {
  SwipeableDrawer,
  Typography,
  Box,
  TextField,
  Button,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import useSearchStore from "@/framework/store/useSearchStore";
import Filter from "./Filter";
import RemovableFilter from "./RemovableFilter";
import { useMemo, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./FiltersDrawer.scss";
import Close from "@mui/icons-material/Close";

const FiltersDrawer = ({ open, setOpen }) => {
  const filter = useSearchStore((state) => state.mission);
  const updateParams = useSearchStore((state) => state.updateMissionParams);
  const resetFilters = useSearchStore((state) => state.resetFilters);

  const [activeFilters, setActiveFilters] = useState(0);
  const filters = filter.filter;

  const toggleFilter = (filterName, value) => {
    const updatedFilter = {
      [filterName]: {
        checked: value,
      },
    };
    updateParams({ filter: updatedFilter });
  };

  const topFilters = useMemo(() => {
    const filteredItems = Object.fromEntries(
      Object.entries(filter.filter).filter(
        ([key, value]) => value.checked === true
      )
    );
    let checkedFilters = Object.entries(filteredItems);
    setActiveFilters(checkedFilters.length);

    return checkedFilters.map(([key, value]) => (
      <RemovableFilter key={key} filter={value} handleToggle={toggleFilter} />
    ));
  }, [filter]);

  return (
    <SwipeableDrawer
      open={open}
      onClose={() => setOpen(false)}
      anchor="top"
      keepMounted
      className="filter-drawer"
      data-testid="filter-drawer"
    >
      <Box className="filter-drawer__content">
        <Box className="filter-drawer__content__header">
          <Box className="filter-drawer__content__header__title">
            <FilterListIcon />
            <Typography>{`Filters (${activeFilters})`}</Typography>
          </Box>
          <Box className="filter-drawer__content__header__filters">
            {topFilters}
          </Box>
          <Button
            data-testid="reset-filters"
            onClick={() => resetFilters()}
            className="filter-drawer__content__header__clearBtn"
          >
            <Close />
            Clear All
          </Button>
        </Box>
        <Box className="filter-drawer__content__main">
          <Button
            data-testid="close-filters"
            onClick={() => setOpen(false)}
            className="filter-drawer__content__main__closeBtn"
          >
            <ExpandLessIcon />
          </Button>
          <Box className="filter-drawer__content__main__category">
            <Typography>Completion Status</Typography>
            <Box className="filter-drawer__content__main__category__wrapper">
              <Filter
                filter={filters[["NOT_STARTED"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["IN_PROGRESS"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["COMPLETED"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["CANCELED"]]}
                handleToggle={toggleFilter}
              />
            </Box>
          </Box>
          <Box className="filter-drawer__content__main__category">
            <Typography>TIMELINESS</Typography>
            <Box className="filter-drawer__content__main__category__wrapper">
              <Filter
                filter={filters[["SCHEDULED"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["ON_TIME"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["AT_RISK"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["DELAYED"]]}
                handleToggle={toggleFilter}
              />
            </Box>
          </Box>
          <Box className="filter-drawer__content__main__category">
            <Typography>Issues</Typography>
            <Box className="filter-drawer__content__main__category__wrapper">
              <Filter
                filter={filters[["WARNINGS"]]}
                handleToggle={toggleFilter}
              />
            </Box>
          </Box>
          <Box className="filter-drawer__content__main__category">
            <Typography>Task Types</Typography>
            <Box className="filter-drawer__content__main__category__wrapper">
              <Filter
                filter={filters[["PRE_TRIP"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["POST_TRIP"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["COUPLING"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["VEHICLE_READINESS"]]}
                handleToggle={toggleFilter}
              />
              <Filter filter={filters[["CMV"]]} handleToggle={toggleFilter} />
              <Filter filter={filters[["ADS"]]} handleToggle={toggleFilter} />
              <Filter
                filter={filters[["DEPARTURE_CLEARANCE"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["ARRIVAL_REPORT"]]}
                handleToggle={toggleFilter}
              />
              <Filter
                filter={filters[["POST_TRIP_INSPECTION"]]}
                handleToggle={toggleFilter}
              />
            </Box>
          </Box>
          <Box className="filter-drawer__content__main__category assignee">
            <Typography>ASSIGNEE</Typography>
            <TextField id="select-user" label="select user" variant="filled" />
          </Box>
          <Box className="filter-drawer__content__main__category customer">
            <Typography>Customer</Typography>
            <RemovableFilter
              filter={filters["TORC"]}
              handleToggle={toggleFilter}
              disabled={true}
            />
          </Box>
        </Box>
        <div className="filter-drawer__content__puller" />
      </Box>
    </SwipeableDrawer>
  );
};

export default FiltersDrawer;
