import { Box, Button, Typography } from "@mui/material";

const AssignModal = ({ action, handleClose, confirmAssign }) => {
  const assignee = action.assigned_to_name
    ? action.assigned_to_name
    : action.assigned_to;

  return (
    <Box className="confirmation__box">
      <Box className="confirmation__box__text">
        <Typography>
          Are you sure you want to re-assign this task to yourself?
        </Typography>
        <Typography>
          <b>{assignee}</b> will no longer be responsible for this task.
        </Typography>
      </Box>
      <Box className="confirmation__box__button-group">
        <Button
          data-testid="close-module"
          onClick={handleClose}
          className="cancel"
        >
          No
        </Button>
        <Button onClick={confirmAssign} className="confirm">
          Yes, assign me
        </Button>
      </Box>
    </Box>
  );
};

export default AssignModal;
