import MissionsApi from "@/framework/api/MissionsApi";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

const useGetMissionQuery = (missionUUID: string) => {
  const mission = useQuery({
    queryKey: ["missions", missionUUID],
    queryFn: async () => await MissionsApi.v1.getMission({ uuid: missionUUID }),
    enabled: !isEmpty(missionUUID),
  });

  return mission;
};
export default useGetMissionQuery;
