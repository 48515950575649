import { Backdrop, CircularProgress } from "@mui/material";

const LoadScreen = ({ fullscreen = false }) => {
  return (
    <>
      {fullscreen ? (
        <Backdrop
          open={true}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      )}
    </>
  );
};

export default LoadScreen;
