import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React from "react";

const CustomToggle = ({
  value,
  onChange,
  required,
  name,
  disabled,
  schema,
}) => {
  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      disabled={disabled}
      data-testid={name}
      value={value}
      exclusive
      onChange={handleChange}
      required={required}
      className="custom-toggle-options"
    >
      <ToggleButton
        data-testid={`${name}-affirmative`}
        value={schema.value[0]}
        className="toggle_value_0"
        variant="contained"
      >
        {schema.value[0]}
      </ToggleButton>
      <ToggleButton
        data-testid={`${name}-negative`}
        value={schema.value[1]}
        className="toggle_value_1"
        variant="contained"
      >
        {schema.value[1]}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CustomToggle;
