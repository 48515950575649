import {
  Box,
  Typography,
  Divider,
  Chip,
  Button,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Task from "../tasks/Task";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGetActionsForMissionQuery from "@/framework/query/actions/useGetActionsForMissionQuery";
import { isEmpty } from "lodash";

import {
  getActionStage,
  getDatetimeDisplayText,
  getFormattedDate,
  truncateText,
} from "../../framework/Utils";
import { toast } from "react-toastify";
import { usePutActionAssign } from "@/framework/hooks/actionsHooks";
import useGetMeQuery from "@/framework/query/user/useGetMeQuery";
import LoadScreen from "@/components/LoadScreen";

const AssignmentCard = ({ mission, filterEmail, myMissionsView }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const [className, setClassName] = useState("");

  const { data, isLoading } = useGetActionsForMissionQuery(mission.uuid);
  const { data: me } = useGetMeQuery();

  const putActionAssignQuery = usePutActionAssign(mission.uuid);
  const { mutateAsync: putActionAssign } = putActionAssignQuery;

  const actionData = filterEmail
    ? data?.actions
        .filter((action) => action?.assigned_to === filterEmail)
        .sort((a) => a.state)
        .reverse()
    : data?.actions?.sort((a) => a.state).reverse();

  const handleToggle = (event) => {
    event.stopPropagation();
    setExpanded((prev) => !prev);
  };

  function getAlertMessage(alerts) {
    const message = [];
    for (const alert of alerts) {
      message.push(`${alert.action}: ${alert.detail}`);
    }

    return message.join(", ");
  }

  async function assignAction(action) {
    let response;
    try {
      response = await putActionAssign({
        uuid: action.uuid,
        payload: { user_email: me.email },
      });
    } catch (err) {
      return Promise.reject({
        uuid: action.uuid,
        action: action.title,
        status: err.status,
        detail: err.body.detail,
      });
    }
  }

  async function handleAssignAll(event, actionData) {
    event.stopPropagation();

    const promises = [];
    for (const action of actionData) {
      if (!action.assigned_to && action.state !== "COMPLETED") {
        promises.push(assignAction(action, mission.uuid));
      }
    }
    const results = await Promise.allSettled(promises);

    if (results.length) {
      const failed = results.filter((resp) => resp.status === "rejected");
      const alerts = failed.map((resp) => resp.reason);
      if (alerts.length) {
        toast.error(`Failures: ${getAlertMessage(alerts)}`);
      } else {
        const successful = results.filter(
          (resp) => resp.status === "fulfilled"
        );
        toast.success(
          `Successfully assigned ${me.email} to ${successful.length} action(s)`
        );
      }
    } else {
      toast.info(`No valid actions for assignment`);
    }
  }

  const text = useMemo(() => {
    if (actionData) {
      if (filterEmail) {
        return `${actionData.length} assigned to me`;
      } else {
        const unAssignedActions = actionData
          .filter((a) => a.state != "COMPLETED")
          .filter((action) => isEmpty(action.assigned_to));
        if (isEmpty(unAssignedActions)) {
          return `${
            actionData.filter((a) => a.state != "COMPLETED").length
          } tasks`;
        } else {
          setClassName("alt-color");
          return `${
            unAssignedActions?.filter((a) => a.state != "COMPLETED").length
          } tasks available`;
        }
      }
    }
  }, [filterEmail, actionData]);

  return !isLoading ? (
    <Accordion
      expanded={expanded}
      className="assignment"
      onClick={() => navigate(`/${mission.uuid}`)}
      data-testid="assignment"
    >
      <AccordionSummary>
        <Box className="assignment-card" data-testid="assignment-card">
          <Box className="assignment-card__title">
            <Typography className="assignment-card__title__mission_id">
              {truncateText(mission.load_id)}
            </Typography>
            <div className="assignment-card__title__vehicle">
              <Typography>{truncateText(mission.vehicle_id)}</Typography>
              {mission.trailer_id ? <Divider orientation="vertical" /> : null}
              <Typography>{mission.trailer_id}</Typography>
            </div>
          </Box>
          <Box className="assignment-card__info">
            <Chip
              className={`assignment-card__info__${getActionStage(
                mission.state
              )}`}
              label={getActionStage(mission.state)}
            />
            <Button
              className={`assignment-card__info ${className}`}
              data-testid="open-assignments"
              onClick={handleToggle}
            >
              <AssignmentIcon className="clipboard" />
              <Typography data-testid="task-count">{text}</Typography>
              {expanded ? (
                <ExpandLessIcon className="expand" />
              ) : (
                <ExpandMoreIcon className="expand" />
              )}
            </Button>
          </Box>
          {actionData && expanded && !myMissionsView && (
            <Box className="assignment-card__assignee">
              <Chip
                onClick={(e) => handleAssignAll(e, actionData)}
                data-testid="assign-all-btn"
                label="assign all to me"
              />
            </Box>
          )}
          <Box className="assignment-card__date">
            <Typography>{getDatetimeDisplayText(mission)}</Typography>
          </Box>
          <Box className="assignment-card__status">
            {mission.schedule_status ? (
              <Chip
                className={`assignment-card__status__${mission.schedule_status}`}
                label={mission?.schedule_status.replace(/_/g, " ")}
                data-testid="status-chip"
              />
            ) : null}
          </Box>
          <Box className="assignment-card__more">
            <ChevronRightIcon />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack className="assignment-tasks">
          {actionData?.map((action) => {
            return <Task key={action.uuid} action={action} mission={mission} />;
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  ) : (
    <LoadScreen />
  );
};

export default AssignmentCard;
