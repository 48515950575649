import useGetRouteAdvisorViasQuery from "@/framework/query/routes/useGetRouteAdvisorViasQuery";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";
import { Clear, Keyboard, Map, SmartButton } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import EndIcon from "../css/end.svg";
import {
  StyledButton,
  StyledInput,
  StyledInputRoot,
} from "../css/LapComponentStyles";
import "../css/RouteSelection.scss";
import WaypointIcon from "../css/waypoint.svg";
import { isValidLatLonString } from "../utils/utils";
import MiniMap from "./MiniMap";

const ViaInput = ({ currentVia, options, i, onChange, onRemove, readOnly }) => {
  const [selectOrSpecify, setSelectedOrSpecify] = useState("select");

  const resetCurrentWaypoint = () => {
    onChange(i, {
      name: "custom",
      latlons: [],
    });
  };

  const select = (
    <div>
      <div className="arrival-selections__waypoint_title">
        <Typography className="mission-route-details__subheading">
          WAYPOINT LOCATION
        </Typography>
      </div>
      <div className="arrival-selections__waypoint_route">
        <Stack direction={"row"} spacing={2}>
          <Select
            id={`arrival-selections__waypoint_route-${i}`}
            data-testid={`arrival-selections__waypoint_route`}
            inputProps={{ "aria-label": "Without label" }}
            className="mission-route-details__arrival-options"
            readOnly={readOnly}
            defaultValue=""
            onChange={(e) => onChange(i, e.target.value)}
          >
            {options.map((via) => (
              <MenuItem sx={{ fontSize: "14px" }} key={via.name} value={via}>
                {via.name}
              </MenuItem>
            ))}
          </Select>
          <Tooltip
            title={
              <MiniMap
                coords={currentVia?.latlons?.map((currentViaLatLons) => [
                  currentViaLatLons?.lon,
                  currentViaLatLons?.lat,
                ])}
                icon={WaypointIcon}
              />
            }
          >
            <Button
              size="small"
              variant="outlined"
              disabled={_.isEmpty(currentVia?.latlons)}
            >
              <Map /> Show on Map
            </Button>
          </Tooltip>
          <Tooltip title="Remove Stop">
            <Button
              size="small"
              data-testid={`remove-stop-btn`}
              className="remove-stop-btn"
              variant="outlined"
              onClick={() => onRemove(i)}
            >
              <Clear />
            </Button>
          </Tooltip>
          <Tooltip title="Manual Entry">
            <Button
              size="small"
              data-testid={`manual-stop-entry`}
              className="manual-stop-entry"
              variant="outlined"
              onClick={() =>
                resetCurrentWaypoint() || setSelectedOrSpecify("specify")
              }
            >
              <Keyboard />
            </Button>
          </Tooltip>
        </Stack>
      </div>
    </div>
  );

  const [isError, setIsError] = useState(false);

  const specify = (
    <div>
      <div className="arrival-selections__waypoint_title">
        <Typography className="mission-route-details__subheading">
          WAYPOINT LOCATION
        </Typography>
      </div>
      <div className="arrival-selections__waypoint_route">
        <Stack direction={"row"} spacing={2}>
          <TextField
            data-testid={"manual-stop-entry-input"}
            size="small"
            className="mission-route-details__way-options"
            error={isError}
            onChange={(e) => {
              if (!isValidLatLonString(e.target.value)) {
                setIsError(true);
                onChange(i, {
                  name: "custom",
                  latlons: [],
                });
                return;
              }

              setIsError(false);

              const coordinateParts = e.target.value
                .replace(/\s/g, "")
                .split(",");
              onChange(i, {
                name: "custom",
                latlons: [
                  {
                    lat: _.toNumber(coordinateParts[0]),
                    lon: _.toNumber(coordinateParts[1]),
                  },
                ],
              });
            }}
          />
          <Tooltip
            title={
              <MiniMap
                coords={currentVia?.latlons?.map((currentViaLatLons) => [
                  currentViaLatLons?.lon,
                  currentViaLatLons?.lat,
                ])}
                icon={WaypointIcon}
              />
            }
          >
            <Button
              size="small"
              variant="outlined"
              disabled={_.isEmpty(currentVia?.latlons)}
            >
              <Map /> Show on Map
            </Button>
          </Tooltip>
          <Tooltip title="Remove Stop">
            <Button
              size="small"
              data-testid={`remove-stop-btn`}
              className="remove-stop-btn"
              variant="outlined"
              onClick={() => onRemove(i)}
            >
              <Clear />
            </Button>
          </Tooltip>
          <Tooltip title="Select Known">
            <Button
              size="small"
              data-testid={`select-known-stops`}
              className="select-known-stops"
              variant="outlined"
              onClick={() =>
                resetCurrentWaypoint() || setSelectedOrSpecify("select")
              }
            >
              <SmartButton />
            </Button>
          </Tooltip>
        </Stack>
      </div>
    </div>
  );

  return (
    <div data-testid={`via-${i}`}>
      {selectOrSpecify == "select" ? select : specify}
    </div>
  );
};

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon fontSize="small" />,
          className: "increment",
          "data-testid": "lap-count_increment",
        },
        decrementButton: {
          children: <RemoveIcon fontSize="small" />,
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

const ArrivalSelections = ({
  mapVersion,
  updateArrivalDestination,
  updateArrivalWaypoint,
  action,
  setTriggerQuery,
}) => {
  const [laps, setLaps] = useState(Number(1));
  const isCompleted = action?.state === "COMPLETED";

  const addVia = (item) => {
    setArrivalVias((prev) => [...prev, { ...item, _id: _.uniqueId() }]);
  };

  const changeVia = (i, item) => {
    setArrivalVias((prev) => [
      ...prev.slice(0, i),
      { ...prev[i], ...item },
      ...prev.slice(i + 1),
    ]);
  };

  const removeVia = (i) => {
    setArrivalVias((prev) => prev.filter((_, j) => j !== i));
  };

  const [arrivalLocation, setArrivalLocation] = useState({
    location: "",
    Destination: {},
  });
  const [arrivalVias, setArrivalVias] = useState([]);

  const { data: routeVias } = useGetRouteAdvisorViasQuery(
    arrivalLocation?.Destination,
    mapVersion
  );

  const options = {
    "VTTI Surface Streets": {
      Destination: { lat: 37.189456, lon: -80.395041 },
    },
    "VTTI Highway": {
      Destination: { lat: 37.187241, lon: -80.396111 },
    },
    "Torc Calibration Pad": {
      Destination: { lat: 37.19009, lon: -80.391501 },
    },
    Uvalde: {
      Destination: { lat: 29.106394, lon: -99.754668 },
    },
    Buda: {
      Destination: { lat: 30.06686912562298, lon: -97.83602260207392 },
    },
    DFW: {
      Destination: { lat: 33.0037032905087, lon: -97.31751069115307 },
    },
    Laredo: {
      Destination: { lat: 27.618842, lon: -99.49841 },
    },
  };

  const handleDestinationChange = async (event, key, option) => {
    setArrivalLocation({ ...option, location: key });
    setArrivalVias([]);
  };

  return (
    <div className="arrival-selections">
      {!isCompleted && (
        <Stack direction="column" spacing={2}>
          {_.map(arrivalVias, (currentVia, i) => (
            <ViaInput
              key={`via-${currentVia._id}`}
              currentVia={currentVia}
              i={i}
              options={_.get(routeVias, "via-options", [])}
              readOnly={isCompleted}
              onChange={changeVia}
              onRemove={removeVia}
            />
          ))}
          <div>
            <div className="arrival-selections__arrival_title">
              <Typography className="mission-route-details__subheading">
                ARRIVAL LOCATION
              </Typography>
            </div>
            <div className="arrival-selections__arrival_route">
              <Stack direction={"row"} spacing={2}>
                <Select
                  id="arrival-selections__arrival_route"
                  data-testid="arrival-selection"
                  className="mission-route-details__arrival-options"
                  inputProps={{ "aria-label": "Without label" }}
                  value={arrivalLocation.location}
                  onChange={(event) =>
                    handleDestinationChange(
                      event,
                      event.target.value,
                      options[event.target.value]
                    )
                  }
                >
                  {Object.keys(options)
                    .sort()
                    .map((locationName, index) => (
                      <MenuItem
                        sx={{ fontSize: "14px" }}
                        key={index}
                        value={locationName}
                        disabled={options[locationName]?.disabled}
                      >
                        {locationName}
                      </MenuItem>
                    ))}
                </Select>
                <Tooltip
                  title={
                    <MiniMap
                      coords={[
                        [
                          arrivalLocation?.Destination?.lon || 0,
                          arrivalLocation?.Destination?.lat || 0,
                        ],
                      ]}
                      icon={EndIcon}
                    />
                  }
                >
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={_.isEmpty(arrivalLocation?.Destination)}
                  >
                    <Map /> Show on Map
                  </Button>
                </Tooltip>
              </Stack>
            </div>
          </div>
          {!_.isEmpty(_.filter(arrivalVias, "name")) && (
            <div>
              <div className="arrival-selections__laps_title">
                <Typography className="mission-route-details__subheading">
                  NO. OF LAPS
                </Typography>
              </div>
              <div className="arrival-selections__laps_count">
                <NumberInput
                  id="arrivals__number_input"
                  data-testid="arrivals__number_input"
                  value={laps}
                  disabled={isCompleted}
                  onChange={(event, newValue) => setLaps(newValue)}
                  min={1}
                  max={99}
                />
              </div>
            </div>
          )}
          <Stack spacing={2} direction={"row"} mt={2}>
            {!_.isEmpty(_.get(routeVias, "via-options", [])) && (
              <Button
                size="small"
                data-testid="add-stop-btn"
                variant="outlined"
                onClick={() => addVia({})}
              >
                <AddIcon /> Add Stop
              </Button>
            )}
            <Button
              data-testid="load-routes"
              className="arrival-selections__load-routes-btn"
              type="submit"
              variant="contained"
              disabled={
                !Object.hasOwn(arrivalLocation.Destination || {}, "lat")
              }
              onClick={() => {
                const nonEmptyVias = _.filter(arrivalVias, "name");
                updateArrivalDestination(arrivalLocation);
                updateArrivalWaypoint(
                  !_.isEmpty(nonEmptyVias)
                    ? [
                        {
                          latlons: _.flatMap(nonEmptyVias, (v) => v?.latlons),
                          value: laps,
                        },
                      ]
                    : []
                );
                setTriggerQuery(true);
              }}
            >
              Load Routes
            </Button>
          </Stack>
        </Stack>
      )}

      {isCompleted && (
        <Stack direction={"column"} spacing={2}>
          <>
            <div className="arrival-selections__waypoint_title">
              <Typography className="mission-route-details__subheading">
                WAYPOINT LOCATION
              </Typography>
            </div>
            <div className="arrival-selections__waypoint_route">
              <Typography
                className="mission-route-details__destination"
                data-testid="arrival-selections_waypoint_completed"
              >
                {action?.formData["via_name"]}
              </Typography>
            </div>
          </>

          <>
            <div className="departure-selections__arrival_title">
              <Typography className="mission-route-details__subheading">
                ARRIVAL LOCATION
              </Typography>
            </div>
            <div className="departure-selections____arrival_route">
              <Typography
                className="mission-route-details__destination"
                data-testid="arrival-selections__arrival_route_completed"
              >
                {action?.formData["arrival_location"]}
              </Typography>
            </div>
          </>

          <>
            <div className="arrival-selections__laps_title">
              <Typography className="mission-route-details__subheading">
                NO. OF LAPS
              </Typography>
            </div>
            <div className="arrival-selections__laps_count">
              <Typography
                className="mission-route-details__destination"
                data-testid="arrival-selections__number_laps_completed"
              >
                {action?.formData["lap_count"]}
              </Typography>
            </div>
          </>
        </Stack>
      )}
    </div>
  );
};

export default ArrivalSelections;
