export default {
  VITE_IOT_MQTT_HOST: import.meta.env.VITE_IOT_MQTT_HOST,
  VITE_ENV: import.meta.env.VITE_ENV,
  IOT_AUTHORIZER_NAME: import.meta.env.VITE_IOT_AUTHORIZER_NAME,
  VERSION: import.meta.env.VITE_VERSION,
  VITE_GIT_COMMIT: import.meta.env.VITE_GIT_COMMIT,
  VITE_DATADOG_APPLICATION_ID: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  VITE_DATADOG_CLIENT_TOKEN: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  VITE_DATADOG_ENV: import.meta.env.VITE_DATADOG_ENV,
  TOMTOM_API_KEY: import.meta.env.VITE_TOMTOM_API_KEY,
  AWS_MAP_URL: import.meta.env.VITE_AWS_MAP_URL,
};
