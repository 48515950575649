import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getStateDisplay, truncateText } from "../../framework/Utils";

import "./assets/MissionNavigation.scss";

const MissionNavigation = ({ state, loadId }) => {
  const navigate = useNavigate();
  return (
    <Box className="mission-nav">
      <IconButton data-testid="back-btn" onClick={() => navigate("/")}>
        <ArrowBackIcon />
      </IconButton>
      <Typography>
        <b>{getStateDisplay(state)}</b> {truncateText(loadId)}
      </Typography>
    </Box>
  );
};

export default MissionNavigation;
