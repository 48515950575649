import MissionsApi from "@/framework/api/MissionsApi";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

const useGetMissionByUuidQuery = (uuid: string) => {
  const missionQuery = useQuery({
    queryKey: ["missions", uuid],
    queryFn: async () => await MissionsApi.v1.getMission({ uuid }),
    enabled: !isEmpty(uuid),
  });

  return missionQuery;
};

export default useGetMissionByUuidQuery;
