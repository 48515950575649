import { Chip } from "@mui/material";
import Close from "@mui/icons-material/Close";

import "./Filter.scss";

const RemovableFilter = ({ filter, handleToggle, disabled = false }) => {
  return (
    <Chip
      className="filter removable"
      label={filter?.label}
      onDelete={() => handleToggle(filter?.value, false)}
      deleteIcon={<Close />}
      disabled={disabled}
      data-testid={`${filter.value}-filter-removable`}
    />
  );
};
export default RemovableFilter;
