import { Link } from "@mui/material";

const HyperLink = ({ name, schema }) => {
  return (
    <Link data-testid={name} href={schema.value} target="_blank">
      {schema.title}
    </Link>
  );
};
export default HyperLink;
