import { Box, Typography } from "@mui/material";
import "./assets/MissionProgress.scss";

const MissionProgress = ({ actions }) => {
  const completedActions = actions.filter(
    (action) => action.state === "COMPLETED"
  );

  const inProgressActions = actions.filter(
    (action) => action.state === "INCOMPLETE"
  );

  const notStartedActions =
    actions.length - completedActions.length - inProgressActions.length;

  const inProgressActionsIndex = (index) => {
    return index + completedActions.length;
  };

  const notStartedActionsIndex = (index) => {
    return index + completedActions.length + inProgressActions.length;
  };

  return (
    <Box className="mission-progress">
      <Box className="mission-progress__wrapper">
        <Box className="mission-progress__wrapper__heading">
          <Typography className="mission-progress__wrapper__heading__state">
            Progress Bar
          </Typography>
          <Typography className="mission-progress__wrapper__heading__steps">
            ({completedActions.length}/{actions.length}) complete
          </Typography>
        </Box>
        <Box className="mission-progress__wrapper__steps">
          {completedActions.map((action, index) => (
            <Box key={index} className="mission-progress__wrapper__steps__item">
              <Box
                data-testid={`completed-action-${index}`}
                border={0}
                className={`mission-progress__wrapper__steps__item completed${
                  index === 0
                    ? " first"
                    : index === actions.length - 1
                    ? " last"
                    : ""
                }`}
              />
            </Box>
          ))}
          {inProgressActions.map((action, index) => (
            <Box
              key={inProgressActionsIndex(index)}
              className="mission-progress__wrapper__steps__item"
            >
              <Box
                data-testid={`inprog-action-${inProgressActionsIndex(index)}`}
                border={0}
                className={`mission-progress__wrapper__steps__item in-progress${
                  inProgressActionsIndex(index) === 0
                    ? " first"
                    : inProgressActionsIndex(index) === actions.length - 1
                    ? " last"
                    : ""
                }`}
              />
            </Box>
          ))}
          {Array.from({ length: notStartedActions }).map((_, index) => (
            <Box
              key={notStartedActionsIndex(index)}
              className="mission-progress__wrapper__steps__item"
            >
              <Box
                data-testid={`notstarted-action-${notStartedActionsIndex(
                  index
                )}`}
                border={0}
                className={`mission-progress__wrapper__steps__item not-started${
                  notStartedActionsIndex(index) === 0
                    ? " first"
                    : notStartedActionsIndex(index) === actions.length - 1
                    ? " last"
                    : ""
                }`}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MissionProgress;
