import { Amplify } from "aws-amplify";
import awsExports from "./aws-config";
import App from "./components/App/App";
import { DatadogContextProvider } from "./framework/contexts/Datadog";

Amplify.configure(awsExports);
const Root = ({ datadogRum, datadogLogs }) => {
  return (
    <DatadogContextProvider
      datadogRumClient={datadogRum}
      datadogLogsClient={datadogLogs}
    >
      <App />
    </DatadogContextProvider>
  );
};
export default Root;
