/* istanbul ignore file */
import config from "@/config";
import {
  dataLayer,
  uvaldeFillStyle,
  uvaldeLineStyle,
} from "@/features/map/mapStyle";
import uvaldeGeoJson from "@/features/map/uvalde.geo.json";
import { useGeoCreds } from "@/framework/hooks/userHooks";
import { Signer } from "@aws-amplify/core";
import { MapboxOverlay } from "@deck.gl/mapbox/typed";
import { bbox } from "@turf/bbox";
import { lineString } from "@turf/turf";
import maplibregl from "maplibre-gl";
import { useEffect, useRef, useState } from "react";
import Map, {
  FullscreenControl,
  Layer,
  Source,
  useControl,
} from "react-map-gl";
import useRouteWaypointLayer from "./useRouteWaypointLayer";

const MiniMap = ({ coords, icon }) => {
  const geoCreds = useGeoCreds();
  const mapRef = useRef(null);
  const coordIcon = useRouteWaypointLayer(coords, icon);
  const [mapLoaded, setMapLoaded] = useState(false);

  const transformRequest = (url, resourceType) => {
    if (
      resourceType === "Style" &&
      url.indexOf("https://maps.geo.us-east-1.amazonaws.com") > -1
    ) {
      url = `${config.AWS_MAP_URL}`;
    }
    if (url.includes("amazonaws.com")) {
      return {
        url: Signer.signUrl(url, {
          access_key: geoCreds.accessKeyId,
          secret_key: geoCreds.secretAccessKey,
          session_token: geoCreds.sessionToken,
        }),
      };
    }

    return { url: url || "" };
  };

  const initialView = {
    zoom: 12,
    pitch: 45,
  };

  useEffect(() => {
    if (mapRef.current) {
      const routeLine = lineString([...coords, ...coords]);
      const [minLon, minLat, maxLon, maxLat] = bbox(routeLine);

      mapRef.current.fitBounds(
        [
          [minLon, minLat],
          [maxLon, maxLat],
        ],
        { padding: 20, animate: false, zoom: 12, pitch: 45 }
      );
    }
  }, [mapLoaded]);

  return (
    <>
      {!mapLoaded && "loading..."}
      {geoCreds && (
        <Map
          ref={mapRef}
          style={{
            width: "250px",
            height: "250px",
            transition: "opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
            opacity: mapLoaded ? 1 : 0,
            filter: mapLoaded ? "blur(0px)" : "blur(100px)",
            pointerEvents: mapLoaded ? "auto" : "none",
          }}
          initialViewState={initialView}
          dragRotate={false}
          touchRotate={false}
          mapStyle={`https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/nav-map-esri/style-descriptor`}
          mapLib={maplibregl}
          transformRequest={transformRequest}
          dragPan={true}
          scrollZoom={true}
          doubleClickZoom={false}
          attributionControl={false}
          onLoad={() => {
            setMapLoaded(true);
          }}
        >
          <Source id="dc-route" type="geojson">
            <Layer {...dataLayer} />
          </Source>
          <Source id="uvalde" type="geojson" data={uvaldeGeoJson}>
            <Layer {...uvaldeFillStyle} />
            <Layer {...uvaldeLineStyle} />
          </Source>
          <DeckGLOverlay layers={[coordIcon]} />
          <FullscreenControl />
        </Map>
      )}
    </>
  );
};

const DeckGLOverlay = (props) => {
  const overlay = useControl(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

export default MiniMap;
