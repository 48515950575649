import { Chip, Checkbox } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import "./Filter.scss";

const Filter = ({ filter, handleToggle, alt = false }) => {
  return (
    <Chip
      className={`filter ${alt ? "alt" : ""} ${
        filter.checked ? "checked" : ""
      }`}
      label={filter.label}
      onClick={() => handleToggle(filter.value, !filter?.checked)}
      data-testid={`${filter.value}-filter`}
      icon={
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleIcon />}
          checked={filter.checked}
        />
      }
    />
  );
};
export default Filter;
