import { Box, Typography, Button, Grid } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./assets/MissionDetails.scss";
import {
  capitalizeFirstLetter,
  getTime24,
  toTitleCase,
} from "../../framework/Utils";
import useGetMissionRouteQuery from "../../framework/query/routes/useGetMissionRouteQuery";

const DetailRow = ({ title, data, formatData = (data) => data }) => (
  <Grid container item xs={12} className="mission-details__grid__row">
    <Grid item xs={12} sm={6}>
      <Typography className="mission-details__grid__title" variant="body2">
        {title}:
      </Typography>
    </Grid>

    <Grid item xs={12} sm={6}>
      <Typography className="mission-details__grid__data" variant="body2">
        {data ? formatData(data) : "--"}
      </Typography>
    </Grid>
  </Grid>
);

const MissionDetails = ({ handleToggle, mission }) => {
  const { data: missionRouteData } = useGetMissionRouteQuery(mission?.uuid);

  const renderAddressLines = (line1, line2) => (
    <Box sx={{ paddingBottom: "1.5em" }}>
      {line1 && (
        <p className="mission-details__grid__data" style={{ margin: 0 }}>
          {line1}
        </p>
      )}
      {line2 && (
        <p
          className="mission-details__grid__data"
          style={{ margin: 0, paddingBottom: "1em" }}
        >
          {line2}
        </p>
      )}
    </Box>
  );
  return (
    <Box className="mission-details" data-testid="mission-details">
      <Box className="mission-details__route">
        <Typography>{toTitleCase(mission?.start_display)}</Typography>
        <ArrowRightAltIcon />
        <Typography>{toTitleCase(mission?.destination_display)}</Typography>
      </Box>

      <Box className="mission-details__grid">
        <Box>
          <DetailRow
            title="Mission Type"
            data={mission?.customer_name}
            formatData={(data) => capitalizeFirstLetter(data)}
          />
          <DetailRow
            title="Route Distance"
            data={missionRouteData?.total_miles}
          />
          <DetailRow
            title="Customer"
            data={mission?.customer_name}
            formatData={(data) => capitalizeFirstLetter(data)}
          />
          <DetailRow title="BOL" data={mission?.bol} />
          <DetailRow title="Freight Type" data={mission?.freight_type} />
          <DetailRow title="Truck ID" data={mission?.vehicle_id} />
          <DetailRow title="Trailer ID" data={mission?.trailer_id} />
        </Box>

        <Box>
          <DetailRow
            title="Scheduled Departure Time"
            data={mission?.tms_schedule_departure_time}
            formatData={(data) => getTime24(data)}
          />
          <DetailRow
            title="Scheduled Arrival Time"
            data={mission?.tms_schedule_arrival_time}
            formatData={(data) => getTime24(data)}
          />
          <DetailRow
            title="Estimated trip duration"
            data={missionRouteData?.total_time}
          />
          <DetailRow
            title="Departure Hub Address"
            data={[
              mission?.origin_address_line_1,
              mission?.origin_address_line_2,
            ]}
            formatData={(data) => renderAddressLines(data[0], data[1])}
          />
          <DetailRow
            title="Arrival Hub Address"
            data={[
              mission?.destination_address_line_1,
              mission?.destination_address_line_2,
            ]}
            formatData={(data) => renderAddressLines(data[0], data[1])}
          />
        </Box>
      </Box>

      <Box>
        <Button
          data-testid="close-details"
          className="mission-details__colapseBtn"
          onClick={handleToggle}
        >
          show less <ExpandLessIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default MissionDetails;
