import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ActionsApi } from "../api/ActionsApi";

const usePutActionAssign = (missionId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ uuid, payload }) =>
      ActionsApi.putActionAssignV2({
        actionUuid: uuid,
        requestBody: payload,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["actions", missionId],
      });
      queryClient.invalidateQueries({
        queryKey: ["missions"],
      });
    },
  });
};

export { usePutActionAssign };
