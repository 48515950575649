import { merge } from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

const missionFilters: Record<string, FilterGroup> = {
  "MY_MISSIONS":{
    label: "My Missions",
    value: "MY_MISSIONS",
    checked: true,
  },
  "NOT_STARTED": {
    value: "NOT_STARTED",
    label: "not started",
    checked: true,
  },
  "IN_PROGRESS": {
    label: "in progress",
    value: "IN_PROGRESS",
    checked: true,
  },
  "COMPLETED": {
    label: "completed",
    value: "COMPLETED",
    checked: true,
  },
  "CANCELED": {
    label: "canceled",
    value: "CANCELED",
    checked: true,
  },
  "SCHEDULED": {
    label: "scheduled",
    value: "SCHEDULED",
    checked: true,
  },
  "ON_TIME": {
    label: "tracking on time",
    value: "ON_TIME",
    checked: true,
  },
  "AT_RISK": {
    label: "at risk",
    value: "AT_RISK",
    checked: true,
  },
  "DELAYED": {
    label: "delayed",
    value: "DELAYED",
    checked: true,
  },
  "WARNINGS": {
    label: "warnings",
    value: "WARNINGS",
    checked: false,
  },
  "PRE_TRIP": {
    label: "PRE-TRIP",
    value: "PRE_TRIP",
    checked: true,
  },
  "POST_TRIP": {
    label: "POST-TRIP",
    value: "POST_TRIP",
    checked: true,
  },
  "COUPLING": {
    label: "coupling",
    value: "COUPLING",
    checked: false,
  },
  "VEHICLE_READINESS": {
    label: "vehicle readiness",
    value: "VEHICLE_READINESS",
    checked: false,
  },
  "CMV": {
    label: "CMV inspection",
    value: "CMV",
    checked: false,
  },
  "ADS": {
    label: "ADS inspection",
    value: "ADS",
    checked: false,
  },
  "DEPARTURE_CLEARANCE": {
    label: "departure clearance",
    value: "DEPARTURE_CLEARANCE",
    checked: false,
  },
  "ARRIVAL_REPORT": {
    label: "review arrival report",
    value: "ARRIVAL_REPORT",
    checked: false,
  },
  "POST_TRIP_INSPECTION": {
    label: "post-trip inspection",
    value: "POST_TRIP_INSPECTION",
    checked: false,
  },
  "TORC": {
    label: "customer: Torc",
    value: "TORC",
    checked: true,
  },
  "UNASSIGNED": {
    label: "unassigned",
    value: "UNASSIGNED",
    checked: true,
  },
};

interface FilterGroup {
  label: string;
  checked: boolean;
  value: string;
}

interface SearchStore {
  mission: {
    filter: Record<string, FilterGroup>;
    hub: string;
    search: string;
  };
  updateMissionParams: (params: {
    hub?: string;
    filter?: Record<string, FilterGroup>;
    search?: string;
  }) => void;
  resetFilters: () => void;
}

const useSearchStore = create<SearchStore>()(
  devtools(
    (set) => ({
      mission: {
        filter: missionFilters,
        hub: "",
        search: "",
      },
      updateMissionParams: (params) => {
        set((state) => ({
          mission: {
            ...merge(state.mission, params),
          },
        }));
      },
      resetFilters: () => {  
        set((state) => ({
          mission: {
            filter:  Object.fromEntries(
            Object.entries(state.mission.filter).map(([key, value]) => [
              key, 
              { 
                ...value, 
                checked: key === "MY_MISSIONS" ? value.checked : false 
              }
            ])
          ),
            hub: state.mission.hub,
            search: state.mission.search
          }
        }))
      }
    }),

    { name: "searchStore" }
  )
);

export default useSearchStore;
export type { FilterGroup };
