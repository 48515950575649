import { useState } from "react";
import { Outlet } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const RemoteLayout = () => {
  const browserId = useState(uuidv4()[0]);

  return <Outlet context={{ browserId }} />;
};

export default RemoteLayout;
