import getToken from "./getToken";
import { UserApiClient } from "@torc-robotics/mm-user-client";

const UserApi = new UserApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_USER_API_URL,
  TOKEN: getToken,
}).v1;

export default UserApi;
