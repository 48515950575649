import { Box, Button, Typography } from "@mui/material";
import SearchInput from "./SearchInput";
import ReplayIcon from "@mui/icons-material/Replay";
import AddIcon from "@mui/icons-material/Add";
import useSearchStore from "@/framework/store/useSearchStore";
import Filter from "./Filter";

const Search = ({ setFiltersOpen }) => {
  const updateMissionParams = useSearchStore(
    (state) => state.updateMissionParams
  );
  const filter = useSearchStore((state) => state.mission);
  const resetFilters = useSearchStore((state) => state.resetFilters);
  const filters = filter.filter;
  const isMyMissions = filters["MY_MISSIONS"]?.checked ?? true;

  const toggleFilter = (filterName, value) => {
    const updatedFilter = {
      [filterName]: {
        checked: value,
      },
    };
    updateMissionParams({ filter: updatedFilter });
  };

  const handleSearchChange = (newSearch) => {
    updateMissionParams({ search: newSearch });
  };

  const { search } = useSearchStore((state) => state.mission);
  return (
    <Box className="search-hm" data-testid="search">
      <SearchInput
        search={search}
        updateSearch={handleSearchChange}
        placeholder="Search Mission ID or Truck Name"
      />
      {isMyMissions ? (
        <Box className="search-hm__filter-chips">
          <Filter
            filter={filters["NOT_STARTED"]}
            handleToggle={toggleFilter}
            alt
          />
          <Filter
            filter={filters["IN_PROGRESS"]}
            handleToggle={toggleFilter}
            alt
          />
          <Filter
            filter={filters["COMPLETED"]}
            handleToggle={toggleFilter}
            alt
          />
        </Box>
      ) : (
        <Box className="search-hm__filter-chips">
          <Filter
            filter={filters["NOT_STARTED"]}
            handleToggle={toggleFilter}
            alt
          />
          <Filter
            filter={filters["WARNINGS"]}
            handleToggle={toggleFilter}
            alt
          />
        </Box>
      )}
      <Button
        onClick={() => setFiltersOpen(true)}
        data-testid="open-filters"
        className="search-hm__filters"
      >
        <AddIcon /> <Typography>FILTERS</Typography>
      </Button>
      <Button
        data-testid="reset-filters"
        onClick={() => resetFilters()}
        className="search-hm__refresh"
      >
        <ReplayIcon />
      </Button>
    </Box>
  );
};

export default Search;
