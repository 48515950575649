import VehiclesApi from "@/framework/api/VehiclesAPI";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

const useGetMapVersionQuery = (vehicleId) => {
  const mapVersionQuery = useQuery({
    queryKey: ["vehicles-map-version", vehicleId],
    queryFn: async () =>
      await VehiclesApi.getVehicleVersionV2VehiclesVehicleIdVersionsVersionIdGet(
        {
          vehicleId: vehicleId,
          versionId: "$latest",
        }
      ),
    enabled: !isEmpty(vehicleId),
  });

  return mapVersionQuery;
};

export default useGetMapVersionQuery;
