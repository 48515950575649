import { useMemo, useState } from "react";
import {
  Container,
  Stack,
  LinearProgress,
  Box,
  Typography,
} from "@mui/material";
import AssignmentCard from "./AssignmentCard";
import HubMainTabs from "../navigation/HubMainTabs";
import Search from "../search/Search";
import useGetMissionsQuery from "../../framework/query/missions/useGetMissionsQuery";
import InfiniteScroll from "react-infinite-scroll-component";
import useGetMeQuery from "../../framework/query/user/useGetMeQuery";
import useSearchStore from "../../framework/store/useSearchStore";

import "./Assignments.scss";
import FiltersDrawer from "../search/FiltersDrawer";

const Assignments = () => {
  const { data: me } = useGetMeQuery();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading: missionsLoading,
  } = useGetMissionsQuery();
  const { filter } = useSearchStore((state) => state.mission);
  const [assignedMissions, setAssignedMissions] = useState(0);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const isMyMissions = filter["MY_MISSIONS"]?.checked ?? true;

  const assignments = useMemo(() => {
    return (
      me?.email &&
      data?.pages.map((page) =>
        page.missions?.map((mission) => {
          const myActions = mission?.actions.filter(
            (action) => action?.assigned_to === me?.email
          );
          setAssignedMissions(myActions?.length);

          if (isMyMissions) {
            return myActions?.length > 0 ? (
              <AssignmentCard
                key={mission?.uuid}
                mission={mission}
                filterEmail={me?.email}
                myMissionsView={isMyMissions}
              />
            ) : null;
          } else {
            return (
              <AssignmentCard
                key={mission?.uuid}
                mission={mission}
                filterEmail={null}
                myMissionsView={isMyMissions}
              />
            );
          }
        })
      )
    );
  }, [data, me?.email, isMyMissions]);

  return (
    <Container
      id="assignment-container"
      className="assignment-container"
      data-testid="assignments"
    >
      <HubMainTabs />
      <Search setFiltersOpen={setFiltersOpen} />
      {!missionsLoading ? (
        <Box className="assignment-container__wrapper">
          <InfiniteScroll
            dataLength={
              data?.pages.reduce(
                (acc, page) => acc + page.missions.length,
                0
              ) || 0
            }
            next={fetchNextPage}
            hasMore={
              isMyMissions && assignedMissions < 50 ? false : hasNextPage
            }
            loader={<LinearProgress />}
            scrollableTarget="assignment-container"
            endMessage={
              data?.pages.reduce((acc, page) => acc + page.missions.length, 0) >
              0 ? (
                <Typography
                  data-testid="End-of-results"
                  sx={{ display: "flex", justifyContent: "center" }}
                  variant="overline"
                >
                  End of results
                </Typography>
              ) : (
                <Typography
                  data-testid="No-Missions"
                  sx={{ display: "flex", justifyContent: "center" }}
                  variant="overline"
                >
                  No missions found
                </Typography>
              )
            }
          >
            <Stack>{assignments}</Stack>
          </InfiniteScroll>
        </Box>
      ) : (
        <LinearProgress />
      )}
      <FiltersDrawer open={filtersOpen} setOpen={setFiltersOpen} />
    </Container>
  );
};
export default Assignments;
