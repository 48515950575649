import UserApi from "../../api/UsersAPI";
import { useQuery } from "@tanstack/react-query";

const useGetMeQuery = () => {
  const me = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => await UserApi.getMeV1MeGet(),
  });

  return me;
};
export default useGetMeQuery;
