import { Error } from "@mui/icons-material";
import { Typography } from "@mui/material";
import "./css/ErrorListTemplate.scss";

function ErrorListTemplate(props) {
  const { errors } = props;
  const require_errors = errors.filter((error) =>
    ["required", "type"].includes(error.name)
  );
  const other_errors = errors.filter(
    (error) => !["required", "type"].includes(error.name)
  );
  const type = props.formContext?.type;
  return (
    <div className="error">
      {require_errors.length ? (
        <div data-testid="required-error" className="error__item">
          <Error />
          <Typography className="error__item__stack">
            {type === "request" || type === "retry"
              ? `${require_errors.length} selections required for departure are empty. All sections are required for departure
            clearance.`
              : `${require_errors.length} required fields are empty or invalid.`}
          </Typography>
        </div>
      ) : (
        ""
      )}

      {other_errors.map((error) => (
        <div
          key={error.stack}
          data-testid="other__error__item"
          className="error__item"
        >
          <Error />
          <Typography className="error__item__stack">
            {error?.message}
          </Typography>
        </div>
      ))}
    </div>
  );
}
export default ErrorListTemplate;
