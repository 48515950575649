import { datadogLogs as defaultDatadogLogs } from "@datadog/browser-logs";
import { datadogRum as defaultDatadogRum } from "@datadog/browser-rum";
import { createContext, useMemo } from "react";
import { telemetry } from "../events/telemetry";

const setLogger = (datadogLogsClient) => {
  let datadogLogs;
  if (datadogLogsClient) {
    datadogLogs = datadogLogsClient;
    datadogLogs.setGlobalContext({
      service: "hub-management",
      team: "mission-management",
    });
  } else {
    datadogLogs = defaultDatadogLogs;
    datadogLogs.init({
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "hub-management",
      team: "mission-management",
      version: String(import.meta.env.VITE_VCS_SHORT_REF),
      env: import.meta.env.VITE_ENV,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ["log", "info", "error"],
      sessionSampleRate: 100,
      useSecureSessionCookie: true,
    });
  }
  datadogLogs.logger.info("initialized datadog", {
    version: String(import.meta.env.VITE_VCS_SHORT_REF),
  });
  return datadogLogs;
};

const setRum = (datadogRumClient) => {
  if (
    !datadogRumClient ||
    datadogRumClient.getInitConfiguration().applicationId !=
      (import.meta.env.VITE_DATADOG_APPLICATION_ID || "unknown")
  ) {
    defaultDatadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "hub-management",
      env: import.meta.env.VITE_ENV,
      version: String(import.meta.env.VITE_VCS_SHORT_REF),
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      allowedTracingUrls: [
        {
          match: /https:\/\/missions.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/users.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/vehicles.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/notes.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/health-monitoring.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/routes.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
        {
          match: /https:\/\/actions.*\.api\.cloud\.torc\.tech/,
          propagatorTypes: ["datadog"],
        },
      ],
      enableExperimentalFeatures: ["clickmap"],
      defaultPrivacyLevel: "allow",
    });
    return defaultDatadogRum;
  } else {
    return datadogRumClient;
  }
};

export const DatadogContext = createContext(null);

export function DatadogContextProvider({
  datadogRumClient,
  datadogLogsClient,
  children,
}) {
  const datadogRum = useMemo(() => setRum(datadogRumClient), []);
  const datadogLogs = useMemo(() => setLogger(datadogLogsClient), []);
  const value = {
    datadogRum: datadogRum,
    datadogLog: datadogLogs,
    telemetry: telemetry(datadogRum),
  };
  return (
    <DatadogContext.Provider value={value}>{children}</DatadogContext.Provider>
  );
}
