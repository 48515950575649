import MissionsApi from "@/framework/api/MissionsApi";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

const useGetMissionByLoadIdQuery = (loadId: string | null) => {
  const missionQuery = useQuery({
    queryKey: ["missions", loadId],
    queryFn: async () => await MissionsApi.v1.getMissions({ loadId }),
    enabled: !isEmpty(loadId),
  });

  return missionQuery;
};

export default useGetMissionByLoadIdQuery;
