import { IconLayer } from "@deck.gl/layers";
import WaypointIcon from "../css/waypoint.svg";

const useRouteWaypointLayer = (coords, icon = WaypointIcon) => {
  const getPosition = (d) => [d[0], d[1]];
  const layer = new IconLayer({
    id: "waypoint-icon-layer",
    data: coords,
    pickable: true,
    wrapLongitude: true,
    getPosition: getPosition,
    sizeScale: 0,
    sizeMinPixels: 40,
    sizeUnits: "meters",
    getIcon: () => ({
      url: new URL(icon, import.meta.url).href,
      width: 30,
      height: 35,
      anchorY: 32,
    }),
  });

  return layer;
};

export default useRouteWaypointLayer;
