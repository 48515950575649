import { IconLayer } from "@deck.gl/layers";
import EndIcon from "../css/end.svg";

const useRouteEndLayer = (endCoords) => {
  const endLayer = new IconLayer({
    id: "end-icon-layer",
    data: endCoords,
    pickable: false,
    wrapLongitude: true,
    getPosition: endCoords,
    sizeScale: 0,
    sizeMinPixels: 40,
    sizeUnits: "meters",
    getIcon: () => ({
      url: new URL(EndIcon, import.meta.url).href,
      width: 30,
      height: 35,
      anchorY: 32,
    }),
  });

  return endLayer;
};

export default useRouteEndLayer;
