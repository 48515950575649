import MissionVehicleAssignedBanner from "@/features/mission/MissionVehicleAssignedBanner";
import useGetMissionByUuidQuery from "@/framework/query/missions/useGetMissionByUuidQuery";
import useGetMapVersionQuery from "@/framework/query/routes/useGetMapVersionQuery";
import useGetRoutesQuery from "@/framework/query/routes/useGetRoutesQuery";
import useLastKnownLocation from "@/framework/query/routes/useLastKnownLocation";
import { Map } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useMemo, useState } from "react";
import "../css/RouteSelection.scss";
import StartIcon from "../css/start.svg";
import ArrivalSelections from "./ArrivalSelections";
import MiniMap from "./MiniMap";
import NoRoutes from "./NoRoutes";
import RouteSelectionMap from "./RouteSelectionMap";
import VehicleFaultsBanner from "./VehicleFaultsBanner";

const RouteSelection = ({ mission, vehicle, postRoute, action }) => {
  const [arrivalSelection, setArrivalSelection] = useState();
  const [selectedRoute, setSelectedRoute] = useState();
  const [arrivalVias, setArrivalVias] = useState();
  const [triggerQuery, setTriggerQuery] = useState(false);
  const isCompleted = action?.state === "COMPLETED";

  const { data: vehicleStatus } = useLastKnownLocation(vehicle?.vehicle_id);
  const { data: version } = useGetMapVersionQuery(vehicle?.vehicle_id);

  const mapVersion =
    version && Object.hasOwn(version, "map_version")
      ? version["map_version"]
      : "";
  const vehicleGen = vehicle?.gen ? parseFloat(vehicle?.gen) : 2.3;

  const { data: vehicleCurrentMission } = useGetMissionByUuidQuery(
    vehicle?.current_mission
  );
  const isVehicleOnOtherMission =
    vehicleCurrentMission?.load_Id !== mission?.load_id;

  const {
    data: getRoutes,
    isFetching,
    isError,
    isFetched,
  } = useGetRoutesQuery(
    vehicleStatus,
    arrivalSelection?.Destination,
    mapVersion,
    arrivalVias,
    !isCompleted && (vehicleGen >= 3.0 ? true : triggerQuery)
  );

  const updateArrivalDestination = async (arrival) => {
    setArrivalSelection(arrival);
  };

  const updateArrivalWaypoint = async (vias) => {
    setArrivalVias(vias);
  };

  console.log(vehicleStatus);

  const handleRouteChange = async (event) => {
    const selected_route_id = event.target.value;
    const { estimated_time_minutes, length_miles } =
      getRoutes.data.routes.find(
        (route) => route.route_id === selected_route_id
      )?.summary_info || {};

    setSelectedRoute(selected_route_id);
    postRoute(selected_route_id, estimated_time_minutes, length_miles);
  };

  useMemo(() => {
    if (isFetched && !isError && getRoutes?.data?.routes) {
      setSelectedRoute(getRoutes.data.routes?.[0]?.["route_id"]);
      const { estimated_time_minutes, length_miles } =
        getRoutes.data.routes?.[0]?.summary_info || {};
      postRoute(
        getRoutes.data.routes?.[0]?.["route_id"],
        estimated_time_minutes,
        length_miles
      );
    } else {
      setSelectedRoute(null);
    }
    setTriggerQuery(false);
  }, [getRoutes]);

  const generateRouteOptions = () => {
    return (
      <>
        <Typography className="mission-route-details__route-subheading">
          Currently available options:
        </Typography>
        <Select
          id="route-selection"
          value={selectedRoute}
          data-testid="route-selection"
          inputProps={{ "aria-label": "Without label" }}
          className="mission-route-details__route-options"
          onChange={(e) => handleRouteChange(e)}
        >
          {getRoutes.data.routes.map((option, index) => (
            <MenuItem
              sx={{ fontSize: "14px" }}
              key={index}
              value={option.route_id}
            >
              {index === 0 ? "Best Route" : `Alternate ${index}`} (Duration:{" "}
              {option.summary_info.estimated_time_minutes} min | Distance:{" "}
              {option.summary_info.length_miles} miles)
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  return (
    <>
      {vehicleStatus?.healthy !== undefined &&
      !vehicleStatus?.healthy &&
      !isCompleted ? (
        <VehicleFaultsBanner vehicleId={vehicle.vehicle_id} />
      ) : null}
      {isVehicleOnOtherMission && (
        <MissionVehicleAssignedBanner
          vehicleId={vehicle?.vehicle_id}
          loadId={vehicleCurrentMission?.load_id}
          isV2={false}
        />
      )}
      <Stack className="route-details" sx={{ padding: 2 }}>
        <Box sx={{ borderBottom: "1px solid #d7d7d7" }}>
          <Typography className="mission-route-details__mission-label">
            {mission?.load_id}
          </Typography>
          <Typography className="mission-route-details__truck-name">
            {vehicle?.vehicle_id}
            {vehicle?.name ? `-${vehicle.name}` : null}
          </Typography>
        </Box>

        <Box>
          <Typography className="mission-route-details__heading">
            MISSION PLAN
          </Typography>
          <Box
            sx={{
              border: "1px solid #d7d7d7",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Stack direction="column" spacing={2}>
              <div className="departure-selections">
                <div className="departure-selections__departure_title">
                  <Typography className="mission-route-details__subheading">
                    DEPARTURE LOCATION
                  </Typography>
                </div>
                <div className="departure-selections__departure_route">
                  <Stack direction="row" spacing={2}>
                    <Select
                      defaultValue="default"
                      disabled={true}
                      className="mission-route-details__departure-options"
                    >
                      <MenuItem value="default">
                        {mission?.start_display
                          ? `${mission?.start_display} ${
                              mission?.start_zone ? mission?.start_zone : ""
                            }`
                          : "N/A"}
                      </MenuItem>
                    </Select>
                    <Tooltip
                      title={
                        <MiniMap
                          coords={[[vehicleStatus?.lon, vehicleStatus?.lat]]}
                          icon={StartIcon}
                        />
                      }
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        disabled={!vehicleStatus?.lat}
                      >
                        <Map /> Show on Map
                      </Button>
                    </Tooltip>
                  </Stack>
                </div>
                {vehicleGen < 3.0 ? (
                  <>
                    <div className="departure-selections__arrival_title">
                      <Typography className="mission-route-details__subheading">
                        ARRIVAL LOCATION
                      </Typography>
                    </div>
                    <div className="departure-selections__arrival_route">
                      <Typography className="mission-route-details__destination">
                        {mission?.destination_display
                          ? `${mission?.destination_display} ${
                              mission?.destination_zone
                                ? mission?.destination_zone
                                : ""
                            }`
                          : "N/A"}
                      </Typography>
                    </div>
                  </>
                ) : null}
              </div>
              {vehicleGen >= 3.0 ? (
                <ArrivalSelections
                  updateArrivalDestination={updateArrivalDestination}
                  updateArrivalWaypoint={updateArrivalWaypoint}
                  mapVersion={mapVersion}
                  current_location={vehicleStatus}
                  action={action}
                  setTriggerQuery={setTriggerQuery}
                />
              ) : null}
            </Stack>
          </Box>
        </Box>
        <Box>
          <Typography className="mission-route-details__heading">
            ROUTE SELECTION
          </Typography>
          <Box
            sx={{
              border: "1px solid #d7d7d7",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            {vehicleGen < 3.0 ? (
              <Grid container spacing={2}>
                <Grid item>
                  <Typography className="mission-route-details__subheading">
                    PLANNED ROUTE
                  </Typography>
                </Grid>
                <Grid item sx={{ opacity: isCompleted ? 0.5 : 1 }}>
                  <Typography className="mission-route-details__selected-route">
                    {mission?.mission_route}
                  </Typography>
                </Grid>
              </Grid>
            ) : !Object.hasOwn(arrivalSelection?.Destination || {}, "lat") ? (
              <Typography
                className="mission-route-details__route-subheading"
                sx={{ opacity: isCompleted ? 0.5 : 1 }}
              >
                {!isCompleted
                  ? "Arrival Location needs to be selected"
                  : action?.formData["selected_route"]}
              </Typography>
            ) : isFetching ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <CircularProgress color="inherit" size={20} />
                <Typography className="mission-route-details__route-subheading">
                  Loading route options
                </Typography>
              </Box>
            ) : !isError ? (
              <Box
                className={`route-selection${
                  getRoutes?.data?.routes?.length > 0 ? "__small" : ""
                }`}
              >
                {getRoutes?.data?.routes &&
                getRoutes.data?.routes?.length > 0 ? (
                  generateRouteOptions()
                ) : (
                  <NoRoutes
                    vehicleStatus={vehicleStatus}
                    mapVersion={mapVersion}
                    arrivalLocation={arrivalSelection}
                    arrivalVias={arrivalVias}
                    errorMessage={
                      getRoutes?.data?.message
                        ? getRoutes.data.message
                        : !triggerQuery
                        ? "Select all desired options and press the load button to fetch routes"
                        : "No routes found"
                    }
                  />
                )}
                <RouteSelectionMap
                  routeId={selectedRoute}
                  arrivalVias={arrivalVias}
                  startCoord={[vehicleStatus?.lon, vehicleStatus?.lat]}
                  endCoord={[
                    arrivalSelection?.Destination?.lon,
                    arrivalSelection?.Destination?.lat,
                  ]}
                />
              </Box>
            ) : (
              <Typography className="mission-route-details__route-subheading">
                Error loading route options
              </Typography>
            )}
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default RouteSelection;
