import { ActionsApi } from "@/framework/api/ActionsApi";
import { Box, Slide, Link } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { toast } from "react-toastify";
import ActionForm from "./forms/ActionForm";
import ReportForm from "./forms/ReportForm";
import RequestForm from "./forms/RequestForm";
import ReviewForm from "./forms/ReviewForm";

const TaskDrawer = ({ action, mission, onClose }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["missions", mission?.uuid, "forms"],
    mutationFn: ({ uuid, action }) =>
      ActionsApi.putActionV2({ actionUuid: uuid, formData: action }),
    onSuccess: (data) => {
      toast.success("Successfully updated mission action " + data?.uuid, {
        toastId: "action-success",
      });
      queryClient.invalidateQueries({
        queryKey: ["actions", mission?.uuid],
      });
    },
    onError: (err) => {
      if (err?.status == 409) {
        const errorDetails = err?.body?.detail;
        const errorMessage = errorDetails?.message;
        const blockingLoadId = errorDetails?.load_id;
        const blockingUuid = errorDetails?.uuid;
        const toastMessage = (
          <div>
            {errorMessage}; Please complete all open tasks for&nbsp;
            <Link
              href={`/hub-management/${encodeURIComponent(blockingUuid)}/tasks`}
              style={{ pointerEvents: !blockingLoadId ? "none" : "" }}
            >
              {blockingLoadId}
            </Link>
            &nbsp;to begin pre-departure actions for this mission
          </div>
        );
        toast.warn(toastMessage, { theme: "light", autoClose: 10000 });
        onClose();
      } else {
        toast.error(
          `An error occured: ${_.get(
            err?.body,
            "detail",
            "Unable to update action."
          )}`
        );
      }
    },
  });

  const onSubmit = (task) => {
    let new_task = { ...task };
    new_task.state = "COMPLETED";
    onSave(new_task);
  };

  const onSave = (task) => {
    if (task.state == "NOT STARTED") task.state = "INCOMPLETE";
    mutation.mutate({
      uuid: task.uuid,
      action: { action: task },
    });

    onClose();
  };

  const getForm = () => {
    if (action.type == "form") {
      return (
        <ActionForm
          action={action}
          mission={mission}
          onSubmit={onSubmit}
          onSave={onSave}
          onCancel={onClose}
        />
      );
    } else if (action.type == "report") {
      return (
        <ReportForm
          missionId={mission.uuid}
          loadId={mission.load_id}
          action={action}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      );
    } else if (
      action.type == "review" ||
      (action.type == "response" && action.mission_state === "REVIEWING")
    ) {
      return (
        <ReviewForm
          action={action}
          onSubmit={onSubmit}
          onSave={onSave}
          onCancel={onClose}
        />
      );
    } else if (
      action.type == "request" ||
      (action.type == "response" &&
        (action.mission_state === "CLEARED" ||
          action.mission_state == "WAIT_ACCEPT"))
    ) {
      return (
        <RequestForm
          action={action}
          mission={mission}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      );
    } else {
      console.error(`action type ${action.type} not supported.`);
      return null;
    }
  };

  return (
    <Slide direction="up" in={action} mountOnEnter unmountOnExit>
      <Box data-testid="task-box">
        <div>{getForm()}</div>
      </Box>
    </Slide>
  );
};

export default TaskDrawer;
