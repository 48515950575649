import { ActionsApi } from "@/framework/api/ActionsApi";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

const useGetActionsForMissionQuery = (missionId: string) => {
  const actions = useQuery({
    queryKey: ["actions", missionId],
    queryFn: async () =>
      await ActionsApi.getActionsV2ActionsGet({ missionUuid: missionId }),
    enabled: !isEmpty(missionId),
  });

  return actions;
};
export default useGetActionsForMissionQuery;
