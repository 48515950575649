import { Box, Typography, Button, Icon } from "@mui/material";
import Locate from "./assets/locate.svg";
import LocateDisabled from "./assets/locate_disabled.svg";
import Tractor from "./assets/tractor.svg";
import Trailer from "./assets/trailer.svg";
import { truncateText } from "../../../framework/Utils";

import "./assets/VehicleLocation.scss";

const VehicleLocation = ({ vehicleId }) => {
  return (
    <Box className="vehicle-location">
      <Box className="vehicle-location__wrapper">
        <Box className="vehicle-location__wrapper__card">
          <Box className="vehicle-location__wrapper__card__icon">
            <Icon>
              <img alt={"truck"} src={Tractor} />
            </Icon>
          </Box>
          <Box className="vehicle-location__wrapper__card__details">
            <Typography className="vehicle-location__wrapper__card__details__header">
              {truncateText(vehicleId)}
            </Typography>
            <Typography className="vehicle-location__wrapper__card__details__subtext">
              000,000 miles
            </Typography>
          </Box>
          <Button className="vehicle-location__wrapper__card__locate">
            <Icon>
              <img alt={"locate-vehicle"} src={Locate} />
            </Icon>
            locate
          </Button>
        </Box>
        <Box className="vehicle-location__wrapper__card">
          <Box className="vehicle-location__wrapper__card__icon">
            <Icon>
              <img alt={"trailer"} src={Trailer} />
            </Icon>
          </Box>
          <Box className="vehicle-location__wrapper__card__details">
            <Typography className="vehicle-location__wrapper__card__details__header">
              TRL-00555
            </Typography>
            <Typography className="vehicle-location__wrapper__card__details__subtext">
              owner: Torc
            </Typography>
          </Box>
          <Button disabled className="vehicle-location__wrapper__card__locate">
            <Icon>
              <img alt={"locate-trailer"} src={LocateDisabled} />
            </Icon>
            locate
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default VehicleLocation;
