import { Container, SwipeableDrawer } from "@mui/material";
import MissionNavigation from "../navigation/MissionNavigation";
import MissionSummary from "./MissionSummary";
import VehicleLocation from "./vehicle/VehicleLocation";
import MissionProgress from "./MissionProgress";
import MissionTabs from "./tabs/MissionTabs";
import { useLocation, matchRoutes, useNavigate } from "react-router-dom";
import useGetMissionQuery from "../../framework/query/missions/useGetMissionQuery";
import useGetActionsForMissionQuery from "../../framework/query/actions/useGetActionsForMissionQuery";
import LoadScreen from "../../components/LoadScreen";
import TaskDrawer from "../tasks/TaskDrawer";
import "./assets/Mission.scss";
import { useMemo } from "react";

const Mission = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const routeMatches = matchRoutes(
    [{ path: ":mission_id/*" }, { path: ":mission_id/tasks/:action_uuid" }],
    location
  );
  const mission_id = routeMatches[0].params.mission_id;
  const action_uuid = routeMatches[0].params.action_uuid
    ? routeMatches[0].params.action_uuid
    : undefined;

  const { data: mission, isLoading: isMissionLoading } =
    useGetMissionQuery(mission_id);

  const { data: actions, isLoading: isActionsLoading } =
    useGetActionsForMissionQuery(mission_id);

  const drawer = useMemo(() => {
    return action_uuid &&
      !isActionsLoading &&
      actions?.actions?.find((el) => el.uuid === action_uuid) ? (
      <SwipeableDrawer
        anchor="bottom"
        /* istanbul ignore next */
        onClose={() => navigate(`/${mission.uuid}`)}
        open={!!action_uuid}
        key={`drawer-${action_uuid}`}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        PaperProps={{ sx: { top: "20%" } }}
        data-testid="task-swipe-drawer"
      >
        <TaskDrawer
          onClose={() => navigate(`/${mission.uuid}`)}
          key={action_uuid}
          action={actions?.actions?.find((el) => el.uuid === action_uuid)}
          mission={mission}
          data-testid="task-container"
        />
      </SwipeableDrawer>
    ) : null;
  }, [action_uuid, isActionsLoading, actions?.actions, mission]);

  return !isMissionLoading && !isActionsLoading ? (
    <>
      <Container className="mission-container" data-testid="mission">
        <MissionNavigation loadId={mission?.load_id} state={mission.state} />
        <MissionSummary mission={mission} />
        <VehicleLocation vehicleId={mission.vehicle_id} />
        <MissionProgress actions={actions.actions} />
        <MissionTabs actions={actions.actions} mission={mission} />
      </Container>
      {drawer}
    </>
  ) : (
    <LoadScreen />
  );
};

export default Mission;
