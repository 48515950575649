import { useEffect, useState } from "react";
import { useLocation, matchRoutes, useNavigate } from "react-router-dom";

const tabRoutes = ["tasks", "health"];

const useTabHandler = () => {
  const [path, setPathname] = useState("tasks");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const routeMatches = matchRoutes(
      [
        {
          path: "/:mission_id",
        },
      ],
      location
    );

    if (routeMatches?.[0]?.route.path === "/:mission_id") {
      navigate("tasks", { replace: true });
      return;
    }
    let pathname = location.pathname.split("/").slice(-1)[0];
    setPathname(tabRoutes.includes(pathname) ? pathname : tabRoutes[0]);
  }, [location.pathname]);

  return path;
};

export { useTabHandler };
