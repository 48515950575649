import { Alert, Box, Grid, Typography } from "@mui/material";
import _ from "lodash";
import "../css/RouteSelection.scss";
import { AddLocation, LocationOn, RepeatOn } from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";

const formatLocation = (point) => {
  return point ? point.toFixed(5) : "0";
};

const NoRoutes = ({
  vehicleStatus,
  arrivalLocation,
  arrivalVias,
  mapVersion,
  errorMessage,
}) => {
  return (
    <Grid container data-testid="no__routes__warning">
      <Grid item xs={12}>
        <Alert severity="warning" sx={{ width: "100%" }}>
          <Typography className="mission-route-details__route-subheading">
            {errorMessage} Verify the following data for accuracy.
          </Typography>
        </Alert>
      </Grid>
      <Grid container spacing={4} sx={{ spacing: "margin-left 20px" }}>
        <Grid item xs={12}>
          <Box
            sx={{
              border: "1px solid #d7d7d7",
              borderRadius: "0px",
              padding: "20px",
            }}
          >
            <Typography className="mission-route-details__route-subheading">
              Map Version: {mapVersion}
            </Typography>
            <RouteTimeline
              vehicleStatus={vehicleStatus}
              arrivalVias={arrivalVias}
              arrivalLocation={arrivalLocation}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const RouteTimeline = ({ vehicleStatus, arrivalVias, arrivalLocation }) => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="success">
            <LocationOn />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h6" component="span">
            Depart
          </Typography>
          <Typography>
            lat/lon:{" "}
            {`${formatLocation(vehicleStatus?.lat)}, ${formatLocation(
              vehicleStatus?.lon
            )}`}
          </Typography>
        </TimelineContent>
      </TimelineItem>

      {arrivalVias?.map((currentVia) =>
        currentVia?.latlons.map((currentLatLon) => (
          <TimelineItem
            key={`${currentVia.name}-${currentLatLon?.lat}-${currentLatLon?.lon}`}
          >
            <TimelineSeparator>
              <TimelineDot color="grey">
                <AddLocation />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="h6" component="span">
                Stop
              </Typography>
              <Typography>
                lat/lon:{" "}
                {`${formatLocation(currentLatLon?.lat)}, ${formatLocation(
                  currentLatLon?.lon
                )}`}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))
      )}

      {!_.isEmpty(arrivalVias) && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="grey">
              <RepeatOn />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" component="span">
              Repeat Loop
            </Typography>
            <Typography>Perform laps x{arrivalVias[0]?.value}</Typography>
          </TimelineContent>
        </TimelineItem>
      )}

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="error">
            <LocationOn />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h6" component="span">
            Arrive
          </Typography>
          <Typography>
            lat/lon:{" "}
            {`${formatLocation(
              arrivalLocation?.Destination?.lat
            )}, ${formatLocation(arrivalLocation?.Destination?.lon)}`}
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default NoRoutes;
