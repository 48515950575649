import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Divider,
  Button,
} from "@mui/material";
import "./assets/HubMainTabs.scss";
import { useState } from "react";
import useSearchStore from "../../framework/store/useSearchStore";

const HubMainTabs = () => {
  const updateParams = useSearchStore((state) => state.updateMissionParams);
  const { filter } = useSearchStore((state) => state.mission);

  const [myAssignments, setMyAssignments] = useState(
    filter["MY_MISSIONS"].checked
  );

  const handleActive = (e) => {
    const updatedFilter = {
      ["MY_MISSIONS"]: {
        checked: e.currentTarget.value === "true",
      },
    };
    setMyAssignments(e.currentTarget.value === "true");
    updateParams({ filter: updatedFilter });
  };
  return (
    <Box className="hub-nav">
      <Box className="hub-nav__selection">
        <Typography>My Hub: </Typography>
        <FormControl variant="standard">
          <Select
            labelId="hub-select"
            id="hub-select"
            value={"Dallas, TX"}
            label="Hub"
          >
            <MenuItem value={"Dallas, TX"}>Dallas, TX</MenuItem>
            <MenuItem value={"Albuquerque, NM"}>Albuquerque, NM</MenuItem>
            <MenuItem value={"Uvalde, TX"}>Uvalde, TX</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className="hub-nav__tabs">
        <Divider flexItem orientation="vertical" className="hub-nav__divider" />
        <Button
          data-testid="my_assignments"
          value={true}
          className={myAssignments ? "active" : ""}
          onClick={(e) => handleActive(e)}
        >
          My Assignments
        </Button>
        <Button
          data-testid="all_assignments"
          value={false}
          className={!myAssignments ? "active" : ""}
          onClick={(e) => handleActive(e)}
        >
          All Assignments
        </Button>
      </Box>
    </Box>
  );
};

export default HubMainTabs;
