import { useInfiniteQuery } from "@tanstack/react-query";
import { type MissionSearchResult } from "@torc-robotics/mm-missions-client";
import useSearchStore from "@/framework/store/useSearchStore";
import { getUTCStartDateTimeStamp } from "@/framework/Utils";
import MissionsApi from "@/framework/api/MissionsApi";

const useGetMissionsQuery = () => {
  const { hub, search } = useSearchStore((state) => state.mission);
  const searchTerm = search.trim() !== "" ? search : null;

  const missionsQuery = useInfiniteQuery({
    getNextPageParam: (lastPage: MissionSearchResult, pages) =>
      lastPage.last_key !== undefined ? lastPage.last_key : undefined,
    initialPageParam: 0,
    queryKey: ["missions", searchTerm],
    queryFn: async ({ pageParam }) => {
      return await MissionsApi.v1.getMissions({
        active: true,
        maxIterate: 100,
        searchType: "DEPARTURES_ARRIVALS",
        pageSize: 50,
        ...(pageParam !== null && { startKey: JSON.stringify(pageParam) }),
        scanIndexForward: true,
        states: [
          "IN_PROGRESS",
          "PENDING",
          "REVIEWING",
          "WAIT_ACCEPT",
          "ACCEPTED",
          "PREPARING",
          "SCHEDULED",
          "CLEARED",
          "ARRIVED",
          "COMPLETED",
        ],
        hub: hub ? hub : "",
        date: getUTCStartDateTimeStamp(),
        includeActionData: "true",
        sortBy: "hub_management_assignment_sort",
        searchText: searchTerm,
      });
    },
  });

  return missionsQuery;
};

export default useGetMissionsQuery;
