import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MissionDetails from "./MissionDetails";
import {
  getDatetimeDisplayText,
  getTime24,
  truncateText,
} from "../../framework/Utils";
import "./assets/MissionSummary.scss";

const MissionSummary = ({ mission }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (event) => {
    event.stopPropagation();
    setExpanded((prev) => !prev);
  };

  return (
    <Accordion expanded={expanded} className="mission-info">
      <AccordionSummary className="mission-info__summary">
        <Typography className="mission-info__summary__id">
          {truncateText(mission?.load_id)}
        </Typography>
        <Typography className="mission-info__summary__date">
          {getDatetimeDisplayText(mission)}
        </Typography>
        <Chip className="mission-info__summary__status" label={mission.state} />
        <Typography className="mission-info__summary__last-updated">
          last updated: {getTime24(mission.last_updated)}
        </Typography>
        {!expanded ? (
          <Button
            data-testid="open-details"
            className="mission-info__summary__details"
            onClick={handleToggle}
          >
            more details <ExpandMoreIcon />
          </Button>
        ) : null}
      </AccordionSummary>
      <AccordionDetails>
        <MissionDetails handleToggle={handleToggle} mission={mission} />
      </AccordionDetails>
    </Accordion>
  );
};

export default MissionSummary;
