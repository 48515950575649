import VehiclesApi from "@/framework/api/VehiclesAPI";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

const useGetVehicleQuery = (vehicle: string) => {
  const me = useQuery({
    queryKey: ["vehicle"],
    queryFn: async () => await VehiclesApi.getVehicleV2({ vehicleId: vehicle }),
    enabled: !isEmpty(vehicle),
  });

  return me;
};
export default useGetVehicleQuery;
