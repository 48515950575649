import {
  Box,
  Typography,
  Chip,
  Icon,
  Button,
  Popover,
  Divider,
  Modal,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserIcon from "./assets/user_icon.svg";
import AssignModal from "./AssignModal";
import { useState } from "react";
import UnAssignModal from "./UnAssignModal";
import { toast } from "react-toastify";
import useGetMeQuery from "@/framework/query/user/useGetMeQuery";
import "./Task.scss";
import { useNavigate } from "react-router-dom";
import { usePutActionAssign } from "@/framework/hooks/actionsHooks";

const Task = ({ action, mission }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalView, setModalView] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { data: me } = useGetMeQuery();

  const putActionAssignQuery = usePutActionAssign(mission?.uuid);
  const { mutateAsync: putActionAssign } = putActionAssignQuery;

  const navigate = useNavigate();

  const confirmAssign = async (event) => {
    event.stopPropagation();
    try {
      await putActionAssign({
        uuid: action.uuid,
        payload: { user_email: me.email },
      });
      toast.success(`Successfully assigned ${me.email} to action`);
    } catch (err) {
      toast.error(err.body.detail);
    }
    handleModalClose(event);
  };

  const handleClosePopover = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleModalOpen = (event, name) => {
    event.stopPropagation();
    setModalView(name);
    setModalOpen(true);
  };

  const handleModalClose = (event) => {
    event.stopPropagation();
    setModalOpen(false);
    setModalView(null);
    setAnchorEl(null);
  };

  const openTask = (event) => {
    event.stopPropagation();
    navigate(`/${mission.uuid}/tasks/${action.uuid}`);
  };

  const popoverOpen = Boolean(anchorEl);

  return (
    <Box className="task" data-testid="task" onClick={openTask}>
      <Box className="task__type">
        <AssignmentIcon />
        <Typography>{action.title}</Typography>
      </Box>
      <Box className="task__assignee">
        {action.assigned_to ? (
          <Box className="task__assignee__details">
            <Icon>
              <img alt={action.assigned_to} src={UserIcon} />
            </Icon>
            <Typography>
              {action.assigned_to_name
                ? action.assigned_to_name
                : action.assigned_to}
            </Typography>
            <Button
              onClick={handleOpenPopover}
              className="task__assignee__details__actions"
              data-testid="open-assignee-tasks"
            >
              <MoreVertIcon />
            </Button>
          </Box>
        ) : action.state !== "COMPLETED" ? (
          <Chip
            onClick={confirmAssign}
            data-testid="assign-task"
            label="assign to me"
          />
        ) : null}
        <Popover
          id={popoverOpen ? "task-popover" : null}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Button
            data-testid="open-unassign"
            onClick={(e) => handleModalOpen(e, "unassign")}
          >
            release assignment
          </Button>
          <Divider sx={{ width: "100%" }} />
          <Button
            data-testid="open-assign"
            onClick={(e) => handleModalOpen(e, "assign")}
            disabled={me?.email === action.assigned_to}
          >
            assign to me
          </Button>
        </Popover>
      </Box>
      <Box className="task__status">
        <Chip
          className={`${action.state}`}
          label={action.state == "INCOMPLETE" ? "in progress" : action.state}
        />
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="assign-unassign-confirmation"
        className="confirmation"
      >
        {modalView === "assign" ? (
          <AssignModal
            handleClose={handleModalClose}
            confirmAssign={confirmAssign}
            action={action}
          />
        ) : (
          <UnAssignModal
            handleClose={handleModalClose}
            actionId={action.uuid}
            mission_uuid={action.mission_uuid}
          />
        )}
      </Modal>
    </Box>
  );
};

export default Task;
