import { Check, Close } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import "./css/ToggleCheck.scss";

const ToggleCheck = ({ value, onChange, required, name, disabled }) => {
  const handleChange = (event) => {
    onChange(event.currentTarget.value == "true");
  };

  return (
    <ToggleButtonGroup
      disabled={disabled}
      data-testid={name}
      value={value}
      exclusive
      onChange={handleChange}
      required={required}
    >
      <ToggleButton
        data-testid={`${name}-affirmative`}
        className="toggle_affirmative"
        value={true}
      >
        <Check />
      </ToggleButton>
      <ToggleButton
        data-testid={`${name}-negative`}
        className="toggle_negative"
        value={false}
      >
        <Close />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleCheck;
